import { PageHeading } from "../../Container";
// Services
import { useIntl } from "react-intl";

const TermsAndConditions = () => {
  const intl = useIntl();

  return (
    <div>
      <PageHeading title={intl.formatMessage({ id: "terms_and_conditions" })} />
      <div>
        {/* Paste terms and conditions HTML code here */}
        CHANGE ME
      </div>
    </div>
  );
};

export default TermsAndConditions;
