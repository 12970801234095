import React, {useState} from "react";

// Services
import {useIntl, FormattedMessage} from "react-intl";

// UI
import {PageHeading} from "../../Container";
import FaqList from "./FaqList";
import Button from "../../Button";
import Modal from "../../Modal";
import DialogTicket from "./DialogTicket";


function Support() {
  const intl = useIntl();

  const [showAdd, setShowAdd] = useState(false);
  const [showSuccessResponse, setShowSuccessResponse] = useState(false);
  const toggleOpenDialog = () => setShowAdd(!showAdd);

  return (
    <div>
      <PageHeading
        title={intl.formatMessage({id: "support"})}
        contentActions={
          <div className="flex flex-col items-center gap-5">
            <Button className="w-32" onClick={toggleOpenDialog}>
              <FormattedMessage id="button_faq"/>
            </Button>
          </div>
        }
      />
      <FaqList/>
      <DialogTicket
        opened={showAdd}
        toggleOpen={toggleOpenDialog}
        setShowSuccessResponse={setShowSuccessResponse}
      />
      <Modal
        opened={showSuccessResponse}
        buttonText="Test Modal"
        title={intl.formatMessage({id: "thanks"})}
        description={intl.formatMessage({id: 'success_ticket_sent'})}
        onExit={() => {setShowSuccessResponse(false)}}
      />
    </div>
  );
}

export default Support;
