import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FolderIcon } from "@heroicons/react/24/solid"
import { NotificationsContext } from "../ui-components/Notifications";

// maxSize: maximum size (in MB) acceptable by the FilePicker instance
// alertSizeExceeded: action to perform when maxSize is exceeded
// checkOnImages: if true, only images or pdf will be accepted on input-file-options.
function FilePicker({ 
  fileChosen,
  setFileChosen,
  maxSize,
  alertSizeExceeded,
  labelCustomStyle,
  checkOnImages = true,
}) {
  const intl = useIntl();
  const [sizeExceeded, setSizeExceeded] = useState(false);
  const { push } = useContext(NotificationsContext);

  if (maxSize === undefined || maxSize < 0) {
    maxSize = 0;
  }
  
  function activateFileInput() {
    document.getElementById("file-input")?.click();
  }

  const handleFileChange = (e) => {
    let newList = [];
      // compute total files size
      let size = 0;

      Object.keys(e.target.files).forEach((key) => {
        newList.push(e.target.files[key]);
        size += e.target.files[key].size;
      });
      setFileChosen(newList);

      // if maxSize is exceeded, alert the user and clear the file input
      if (maxSize !== 0) {
        if(size > maxSize * 1000000) {
            setSizeExceeded(true);
            setFileChosen(undefined);
            
            push({
              title: intl.formatMessage({ id: "size_limit_exceeded_notification" }) + ` (${maxSize}MB)`,
              type: "error",
            });
        } else {
          setSizeExceeded(false)
        }          
      }
  }

  return (
    <>
      <div
        onClick={activateFileInput}
        className={`flex items-center justify-between cursor-pointer px-2 py-1 border shadow-sm focus:ring-am-500 focus:border-am-500 block w-full sm:text-sm ${
          sizeExceeded ? "border-red-500" : "border-gray-300"
        } rounded-md disabled:opacity-50`}
      >
        <div className="flex items-center gap-x-2">
          <div className="h-full flex items-center">
            <FolderIcon className="w-6 h-6 text-gray-400" />
          </div>
          <div style={labelCustomStyle} className="text-gray-500">
            {sizeExceeded
              ? intl.formatMessage({ id: "size_limit_exceeded" }) + ` (${maxSize}MB)`
              : fileChosen?.length > 0
              ? fileChosen.map((f) => {
                  return (
                    <div key={f.name}>
                      {f.name}
                      <br />
                    </div>
                  );
                })
              : intl.formatMessage({ id: "file_picker_placeholder" })}
          </div>
        </div>
        {fileChosen && (
          <div className="h-full flex items-center">
            <XMarkIcon
              className="w-4 h-4 text-gray-400 hover:text-gray-800"
              onClick={(e) => {
                e.stopPropagation();
                setFileChosen(undefined);
              }}
            />
          </div>
        )}
      </div>

      <input
        type="file"
        multiple={true}
        accept={checkOnImages ? "image/*,.pdf" : "*"}
        id="file-input"
        className="hidden"
        onChange={handleFileChange}
      />
    </>
  );
}

export default FilePicker;
