import React from "react";
import { FormattedMessage } from "react-intl";
import { Disclosure } from "@headlessui/react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import AuthenticationService from "../../services/AuthenticationService";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MenuDropdown = ({
                        name, Icon, dropdown, clickable = true, setClickable, onTouchEnd = () => {
  }
                      }) => {
  const { pathname } = useLocation();
  const scopes = AuthenticationService.getUserScopes();

  const filteredDropdownItems = dropdown.filter((item) => {
    // if hidden, exclude
    if (item.hidden) {
      return false;
    }
    // if no scope specified, include
    else if (!item.scopes) {
      return true;
    }
    // if specified scope are met, include
    else {
      return item.scopes.some((r) => scopes.indexOf(r) >= 0);
    }
  });

  // if all items has been filtered out, show nothing
  if (!filteredDropdownItems?.length) {
    return null;
  }

  const active = filteredDropdownItems.reduce(
    (acc, { href }) => (!acc && pathname.indexOf(href) >= 0 ? true : acc),
    false
  );

  return (
    <Disclosure as="div" key={name} className="space-y-1">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames(
              active
                ? "bg-gray-200 text-black"
                : "text-black hover:bg-gray-50 hover:text-gray-900",
              "group w-full flex items-center justify-between px-2 py-2 text-base font-medium rounded-3xl"
            )}
            onClick={() => {
              setClickable();
            }}
          >
            <div className="flex items-center">
              <Icon
                className={classNames(clickable ? "" : "-ml-1",
                  "mr-3 shrink-0 h-6 w-6 text-black group-hover:text-gray-500")}
                aria-hidden="true"
              />
              {clickable &&
                <span className={"ml-1 flex-1"}>
                  <FormattedMessage id={name}/>
                 </span>
              }
            </div>
            {clickable &&
              <svg
                className={classNames(
                  open ? "text-black rotate-90" : "text-black",
                  "ml-3 shrink-0 h-5 w-5 group-hover:text-black transition-colors ease-in-out duration-150"
                )}
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M6 6L14 10L6 14V6Z" fill="currentColor"/>
              </svg>
            }
          </Disclosure.Button>
          <Disclosure.Panel className="space-y-1">
            {clickable && filteredDropdownItems
              .filter((item) => {
                if (!item.scopes) {
                  return true;
                }

                return item.scopes.some((r) => scopes.indexOf(r) >= 0);
              })
              .map((subItem) => {
                const subActive = pathname.indexOf(subItem.href) >= 0;

                return (
                  <Link
                    key={subItem.name}
                    to={subItem.href}
                    className={classNames(
                      subActive ? "bg-gray-50" : "",
                      "group w-full flex items-center pl-12 pr-2 py-2 text-sm font-medium text-black rounded-3xl hover:text-gray-900 hover:bg-gray-50"
                    )}
                    onTouchEnd={() => onTouchEnd()}
                  >
                    <FormattedMessage id={subItem.name}/>
                  </Link>
                );
              })}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default MenuDropdown;
