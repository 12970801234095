import { PageHeading } from "../../Container";
// Services
import { useIntl } from "react-intl";

const PrivacyPolicy = () => {
  const intl = useIntl();

  return (
    <div>
      <PageHeading title={intl.formatMessage({ id: "privacy_policy" })} />
      <div>
        {/* Paste privacy policy HTML code here */}
        CHANGE ME
      </div>
    </div>
  );
};

export default PrivacyPolicy;
